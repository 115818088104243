import * as React from 'react';
import { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Card, FormControl, FormLabel, TextField, useMediaQuery, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";

import './contact.scss';
import { ShowLoaderContext } from "../index";
import ConfirmDialog from "../dialogs/confirmDialog/confirmDialog";
import SuccessDialog from "../dialogs/successDialog/successDialog";
import FailureDialog from "../dialogs/failureDialog/failureDialog";

function Contact() {
    const { showLoader, setShowLoader } = useContext(ShowLoaderContext);
    const isDesktop = useMediaQuery(useTheme().breakpoints.up("md"));
    const [disableForm, setDisableForm] = useState(false);
    const [mobileVisible, setMobileVisible] = useState(false);
    const [emailVisible, setEmailVisible] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [failureDialogOpen, setFailureDialogOpen] = useState(false);
    const [formValues, setFormValues] = useState<any>({
        fullName: {
            value: '',
            error: false
        },
        email: {
            value: '',
            errorEmail: false,
            errorRequired: false
        },
        subject: {
            value: '',
            error: false
        },
        details: {
            value: '',
            error: false
        }
    });

    useEffect(() => {
        setShowLoader(false);
    }, [setShowLoader]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [showLoader]);

    function toggleVisibility(toggle: string) {
        if (toggle === 'mobile') {
            setMobileVisible(!mobileVisible);
        } else if (toggle === 'email') {
            setEmailVisible(!emailVisible);
        }
    }

    function handleChange(name: string, value: string) {
        const newValues = { ...formValues };
        let error = false;

        switch (name) {
            case "fullName":
                newValues.fullName.value = value;
                newValues.fullName.error = newValues.fullName.value.length === 0;
                if (newValues.fullName.error) {
                    error = true;
                }
                break;
            case "email":
                newValues.email.value = value;
                newValues.email.errorEmail = newValues.email.value.length > 0 && !(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(newValues.email.value));
                newValues.email.errorRequired = newValues.email.value.length === 0;
                if (newValues.email.errorEmail || newValues.email.errorRequired) {
                    error = true;
                }
                break;
            case "subject":
                newValues.subject.value = value;
                newValues.subject.error = newValues.subject.value.length === 0;
                if (newValues.subject.error) {
                    error = true;
                }
                break;
            case "details":
                newValues.details.value = value;
                newValues.details.error = newValues.details.value.length === 0;
                if (newValues.details.error) {
                    error = true;
                }
                break;
        }

        setFormValues(newValues);

        return error;
    }

    function submit(e?: React.FormEvent<HTMLFormElement>) {
        e?.preventDefault();

        let hasError = false;
        const formFields = Object.keys(formValues);
        
        formFields.forEach(currentField => {
           const currentValue = formValues[currentField].value;
           const error = handleChange(currentField, currentValue);

           if (error) {
               hasError = true;
           }
        });

        if (!hasError) {
            setConfirmDialogOpen(true);
        }
    }

    function onDialogClose() {
        setConfirmDialogOpen(false);
        setSuccessDialogOpen(false);
        setFailureDialogOpen(false);
    }

    function onConfirmDialogSubmit() {
        setConfirmDialogOpen(false);
        setDisableForm(true);

        const data = {
            fullName: formValues.fullName.value,
            email: formValues.email.value,
            subject: formValues.subject.value,
            details: formValues.details.value,
        };

        fetch('https://contact.storyofsongmin.com/api/saveContact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                if (response.status === 200) {
                    setSuccessDialogOpen(true);
                } else {
                    setFailureDialogOpen(true);
                }
            })
            .catch((err) => {
                console.log(err.message);
                setFailureDialogOpen(true);
            });
    }

    function onFailureDialogRetry() {
        setFailureDialogOpen(false);
        submit();
    }

    return (
        <>
            <Card className="content-card">
                <Typography
                    variant="h2"
                    noWrap
                    sx={{ display: { xs: 'none', md: 'block' } }}
                    className="h2-text"
                >
                    Contact Us
                </Typography>

                <p className="p-text mb-5">
                    We’re just a text or a call away! If you were not able to find answers to your questions from <NavLink className="anchor" to={ '/details' }>this page</NavLink> or need to edit your RSVP, feel free to reach out to us via any of the platforms listed below.
                </p>

                <Box className="contact-content-wrapper" style={ isDesktop ? {} : { width: '100%' } }>
                    <Box className={ isDesktop ? "contact-wrapper contact-wrapper-desktop" : "contact-wrapper details-wrapper-mobile" }>
                        <Box className={ isDesktop ? "contact-item contact-item-desktop" : "contact-item contact-item-mobile" } style={ isDesktop ? { marginRight: '1.5rem' } : {} }>
                            <Box className="contact-item-icon-wrapper">
                                <Icon className="contact-item-icon">phone_iphone</Icon>
                            </Box>
                            <Box className="contact-item-label-wrapper">
                                <label className="label contact-item-label">MOBILE</label>
                                <hr className="hr" />
                            </Box>
                            <Box sx={{ mb: '0.5rem' }}>
                                <label className="label">Song:&nbsp;</label>
                                { mobileVisible ? <a href="tel: +65 94571282" className="label contact-item-text">(+65) 9457 1282</a> : <p className="label" style={{ display: 'inline-block', margin: 0 }}>••••••••••••••</p> }
                            </Box>
                            <Box sx={{ mb: '0.5rem' }}>
                                <label className="label">Si Min:&nbsp;</label>
                                { mobileVisible ? <a href="tel: +65 92996388" className="label contact-item-text">(+65) 9299 6388</a> : <p className="label" style={{ display: 'inline-block', margin: 0 }}>••••••••••••••</p> }
                            </Box>

                            <Button className="eye-button" onClick={ () => toggleVisibility('mobile') }>
                                { mobileVisible ? <><Icon>visibility_off</Icon><span className="label" style={{ marginLeft: '0.5rem' }}>Hide Mobile</span></> : <><Icon>visibility</Icon><span className="label" style={{ marginLeft: '0.5rem' }}>Show Mobile</span></> }
                            </Button>
                        </Box>

                        <Box className={ isDesktop ? "contact-item contact-item-desktop" : "contact-item contact-item-mobile" } style={ isDesktop ? { marginLeft: '1.5rem' } : { marginTop: '3rem' } }>
                            <Box className="contact-item-icon-wrapper">
                                <Icon className="contact-item-icon">mail</Icon>
                            </Box>
                            <Box className="contact-item-label-wrapper">
                                <label className="label contact-item-label">EMAIL</label>
                                <hr className="hr" />
                            </Box>
                            <Box sx={{ mb: '0.5rem' }}>
                                <label className="label">Song:&nbsp;</label>
                                { emailVisible ? <a href="mailto: linxusong1991@gmail.com" className="label contact-item-text">linxusong1991@gmail.com</a> : <p className="label" style={{ display: 'inline-block', margin: 0 }}>•••••••••••••••••••••••</p> }
                            </Box>
                            <Box sx={{ mb: '0.5rem' }}>
                                <label className="label">Si Min:&nbsp;</label>
                                { emailVisible ? <a href="mailto: chuasimin10@gmail.com" className="label contact-item-text">chuasimin10@gmail.com</a> : <p className="label" style={{ display: 'inline-block', margin: 0 }}>•••••••••••••••••••••</p> }
                            </Box>

                            <Button className="eye-button" onClick={ () => toggleVisibility('email') }>
                                { emailVisible ? <><Icon>visibility_off</Icon><span className="label" style={{ marginLeft: '0.5rem' }}>Hide Email</span></> : <><Icon>visibility</Icon><span className="label" style={{ marginLeft: '0.5rem' }}>Show Email</span></> }
                            </Button>
                        </Box>
                    </Box>

                    <Box className="get-in-touch-label-wrapper">
                        <Box sx={{ display: 'inline-block' }}>
                            <hr className="hr line" />
                            <label className="label get-in-touch-label">GET IN TOUCH</label>
                        </Box>
                    </Box>

                    <form className="contact-form" onSubmit={ (event) => submit(event) }>
                        <fieldset className="fieldset" disabled={ disableForm }>
                            <FormControl className="form-wrapper">
                                <FormLabel htmlFor="fullName" className="form-label" sx={{ marginBottom: '0.8rem' }}>Full Name <span className="red-text">*</span></FormLabel>
                                <TextField id="fullName" name="fullName" className="form-input" type="text" placeholder="e.g. Tan Ah Kau" aria-describedby="fullName-help" value={ formValues.fullName.value } error= { formValues.fullName.error } onChange={ e => handleChange(e.target.name, e.target.value) } onBlur={ e => handleChange(e.target.name, e.target.value) } disabled={ disableForm }></TextField>
                                <small id="fullName-help" className={ formValues.fullName.error ? "form-small-text d-flex" : "form-small-text d-none" }>
                                    <Icon className="form-icon" fontSize="small">error_outline</Icon>
                                    <span>We need your <strong style={{ display: 'inline-block' }}>name</strong>.</span>
                                </small>
                            </FormControl>

                            <FormControl className="form-wrapper">
                                <FormLabel htmlFor="email" className="form-label" sx={{ marginBottom: '0.5rem' }}>Email Address <span className="red-text">*</span></FormLabel>
                                <small className="help-text">Do ensure that this is a valid email address as we will respond
                                    to you here.</small>
                                <TextField id="email" name="email" className="form-input" type="text" placeholder="e.g. tanahkau@example.com" aria-describedby="email-help" value={ formValues.email.value } error= { formValues.email.errorEmail || formValues.email.errorRequired } onChange={ e => handleChange(e.target.name, e.target.value) } onBlur={ e => handleChange(e.target.name, e.target.value) } disabled={ disableForm }></TextField>
                                <small id="email-help" className={ formValues.email.errorEmail ? "form-small-text d-flex" : "form-small-text d-none" }>
                                    <Icon className="form-icon" fontSize="small">error_outline</Icon>
                                    <span>Please enter a <strong style={{ display: 'inline-block' }}>valid</strong> email address.</span>
                                </small>
                                <small id="email-help" className={ formValues.email.errorRequired ? "form-small-text d-flex" : "form-small-text d-none" }>
                                    <Icon className="form-icon" fontSize="small">error_outline</Icon>
                                    <span>This field is <strong style={{ display: 'inline-block' }}>required</strong>.</span>
                                </small>
                            </FormControl>

                            <FormControl className="form-wrapper">
                                <FormLabel htmlFor="subject" className="form-label" sx={{ marginBottom: '0.8rem' }}>Subject <span className="red-text">*</span></FormLabel>
                                <TextField id="subject" name="subject" className="form-input" type="text" placeholder="e.g. Help needed in editing RSVP" aria-describedby="subject-help" value={ formValues.subject.value } error= { formValues.subject.error } onChange={ e => handleChange(e.target.name, e.target.value) } onBlur={ e => handleChange(e.target.name, e.target.value) } disabled={ disableForm }></TextField>
                                <small id="subject-help" className={ formValues.subject.error ? "form-small-text d-flex" : "form-small-text d-none" }>
                                    <Icon className="form-icon" fontSize="small">error_outline</Icon>
                                    <span>This field is <strong style={{ display: 'inline-block' }}>required</strong>.</span>
                                </small>
                            </FormControl>

                            <FormControl className="form-wrapper">
                                <FormLabel htmlFor="details" className="form-label" sx={{ marginBottom: '0.8rem' }}>Message <span className="red-text">*</span></FormLabel>
                                <TextField id="details" name="details" className="form-input" type="text" placeholder="e.g. I accidentally put 999 as my phone number, can help edit?" aria-describedby="details-help" multiline rows={ 5 } value={ formValues.details.value } error= { formValues.details.error } onChange={ e => handleChange(e.target.name, e.target.value) } onBlur={ e => handleChange(e.target.name, e.target.value) } disabled={ disableForm }></TextField>
                                <small id="details-help" className={ formValues.details.error ? "form-small-text d-flex" : "form-small-text d-none" }>
                                    <Icon className="form-icon" fontSize="small">error_outline</Icon>
                                    <span>This field is <strong style={{ display: 'inline-block' }}>required</strong>.</span>
                                </small>
                            </FormControl>

                            <Box className="send-message-button-container">
                                <Button type="submit" className="send-message-button" disabled={ disableForm }>Submit</Button>
                            </Box>
                        </fieldset>
                    </form>
                </Box>
            </Card>

            <ConfirmDialog mode={ "contact" } open={ confirmDialogOpen } onClose={ () => onDialogClose() } onSubmit={ () => onConfirmDialogSubmit() } />
            <SuccessDialog mode={ "contact" } open={ successDialogOpen } onClose={ () => onDialogClose() } />
            <FailureDialog mode={ "contact" } open={ failureDialogOpen } onClose={ () => onDialogClose() } onRetry={ () => onFailureDialogRetry() } />
        </>
    );
}
export default Contact;