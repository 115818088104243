import * as React from 'react';
import { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Slide, useMediaQuery, useTheme } from "@mui/material";

import './App.scss';
import logo from './assets/logo-storyofsongmin.png';
import { pages, ShowLoaderContext, ToolbarHiddenContext } from "./index";

function App(props: any) {
    const { toolbarHidden, setToolbarHidden } = useContext(ToolbarHiddenContext);
    const { setShowLoader } = useContext(ShowLoaderContext);
    const location = useLocation();
    const isDesktop = useMediaQuery(useTheme().breakpoints.up("md"));
    const [currentPath, setCurrentPath] = useState('Home');
    const [lastScrollTop, setLastScrollTop] = useState(0);

    useEffect(() => {
        const path = location.pathname;

        if (path === '/rsvp') {
            document.title = 'RSVP';
            setCurrentPath('RSVP');
        } else if (path === '/contact') {
            document.title = 'Contact';
            setCurrentPath('Contact Us');
        } else {
            const currentPage = pages.find((page) => page.path === path);
            document.title = currentPage !== undefined ? currentPage?.text : 'Home';
            setCurrentPath(currentPage !== undefined ? currentPage?.text : 'Home');
        }

        setToolbarHidden(false);
        setShowLoader(true);
    }, [location.pathname, setShowLoader, setToolbarHidden]);

    useEffect(() => {
        const handleScroll = (event: any) => {
            if (event?.target?.scrollingElement?.scrollTop) {
                setToolbarHidden(lastScrollTop < event.target!.scrollingElement.scrollTop && event.target!.scrollingElement.scrollTop > (isDesktop ? 100 : 64));

                setLastScrollTop(event.target!.scrollingElement.scrollTop);
            }
        }

        window.addEventListener("scroll", handleScroll, true);

        return () => window.removeEventListener("scroll", handleScroll, true);
    }, [isDesktop, lastScrollTop, setToolbarHidden]);

    return (
        <>
            <Slide appear={ false } direction="down" in={ !toolbarHidden }>
                <AppBar className="app-bar" position="fixed">
                    <Container maxWidth="xl">
                        <Toolbar disableGutters sx={{ height: { xs: '64px', md: '100px' } }}>
                            <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 3.5 }}>
                                <NavLink to="/home">
                                    <img alt="" src={ logo } style={{ height: '48px' }} />
                                </NavLink>
                            </Box>

                            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={ props.toggleDrawer(true) }
                                    className="text-color"
                                >
                                    <MenuIcon />
                                </IconButton>

                                <Typography
                                    variant="h2"
                                    noWrap
                                    sx={{
                                        display: { xs: 'flex', md: 'none' },
                                        ml: 1
                                    }}
                                    className="current-page-text"
                                >
                                    { currentPath }
                                </Typography>
                            </Box>

                            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                { pages.map((page) => (
                                    <Button
                                        component={ NavLink }
                                        key={ page.text }
                                        to={ page.path }
                                        sx={{ m: 2 }}
                                        className="router-buttons text-color bodoni-font bodoni-font-desktop"
                                    >
                                        { page.text }
                                    </Button>
                                )) }
                            </Box>

                            <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                                <NavLink
                                    className="rsvp-button bodoni-font bodoni-font-desktop"
                                    to="/rsvp"
                                >
                                    RSVP
                                </NavLink>
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>
            </Slide>
        </>
    );
}
export default App;
