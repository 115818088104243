import * as React from 'react';
import { useNavigate } from "react-router-dom";
import Icon from "@mui/material/Icon";
import DialogTitle from "@mui/material/DialogTitle";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import './successDialog.scss';

function SuccessDialog(props: any) {
    const navigate = useNavigate();

    const handleClose = () => {
        props.onClose();
    };

    const handleResubmit = () => {
        props.onResubmit();
    }

    const handleBackToHome = () => {
        navigate("/home");
    }

    return (
        <>
            <Dialog
                open={ props.open }
                onClose={ handleClose }
            >
                <DialogTitle className="dialog-header">
                    <Icon className="success-check">check_circle</Icon>
                    Success!
                </DialogTitle>
                <DialogContent className="dialog-content">
                    <p style={ props.mode === 'rsvp' ? { display: 'block' } : { display: 'none' } }>
                        Thank you! We have received your RSVP. You may verify your submission <span className="anchor" onClick={ handleClose }>here</span>.
                    </p>
                    <p style={ props.mode === 'contact' ? { display: 'block' } : { display: 'none' } }>
                        We have received your message and will get back to you asap. You may verify your submission <span className="anchor" onClick={ handleClose }>here</span>.
                    </p>
                </DialogContent>
                <DialogActions className="dialog-footer success-footer">
                    <Button className="primary-button" onClick={ handleResubmit } style={ props.mode === 'rsvp' ? { display: 'block' } : { display: 'none' } } autoFocus>Submit Another</Button>
                    <Button className="primary-button" onClick={ handleBackToHome } style={ props.mode === 'contact' ? { display: 'block' } : { display: 'none' } } autoFocus>Back To Home</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default SuccessDialog;