import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import './confirmDialog.scss';
import Icon from "@mui/material/Icon";

function ConfirmDialog(props: any) {
    const handleClose = () => {
        props.onClose();
    };

    const handleSubmit = () => {
        props.onSubmit();
    }

    return (
        <>
            <Dialog
                open={ props.open }
                onClose={ handleClose }
            >
                <DialogTitle>
                    Confirm form submission?
                </DialogTitle>
                <DialogContent>
                    <Icon className="dialog-icon">error</Icon>
                    <p>
                        Are you sure you wish to submit the form? Do check that all details are correct.
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button className="secondary-button" onClick={ handleClose }>Cancel</Button>
                    <Button className="primary-button" onClick={ handleSubmit } autoFocus>Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default ConfirmDialog;