import * as React from 'react';
import { useContext, useEffect, useRef, useState } from "react";
import { Button, Card, Fade } from "@mui/material";
import Typography from "@mui/material/Typography";
import Icon from '@mui/material/Icon';
import Box from "@mui/material/Box";
import Masonry from '@mui/lab/Masonry';

import './gallery.scss';
import LightBox from './lightBox/light-box';
import { LightBoxOpenContext, ShowLoaderContext } from "../index";
import pws1Thumb from '../assets/storyofsongmin-pws-1-thumb.jpg';
import pws2Thumb from '../assets/storyofsongmin-pws-2-thumb.jpg';
import pws3Thumb from '../assets/storyofsongmin-pws-3-thumb.jpg';
import pws4Thumb from '../assets/storyofsongmin-pws-4-thumb.jpg';
import pws5Thumb from '../assets/storyofsongmin-pws-5-thumb.jpg';
import pws6Thumb from '../assets/storyofsongmin-pws-6-thumb.jpg';
import pws7Thumb from '../assets/storyofsongmin-pws-7-thumb.jpg';
import pws8Thumb from '../assets/storyofsongmin-pws-8-thumb.jpg';
import pws9Thumb from '../assets/storyofsongmin-pws-9-thumb.jpg';
import pws10Thumb from '../assets/storyofsongmin-pws-10-thumb.jpg';
import pws11Thumb from '../assets/storyofsongmin-pws-11-thumb.jpg';
import pws12Thumb from '../assets/storyofsongmin-pws-12-thumb.jpg';
import pws13Thumb from '../assets/storyofsongmin-pws-13-thumb.jpg';
import pws14Thumb from '../assets/storyofsongmin-pws-14-thumb.jpg';
import pws15Thumb from '../assets/storyofsongmin-pws-15-thumb.jpg';
import pws16Thumb from '../assets/storyofsongmin-pws-16-thumb.jpg';
import pws17Thumb from '../assets/storyofsongmin-pws-17-thumb.jpg';
import pws18Thumb from '../assets/storyofsongmin-pws-18-thumb.jpg';
import pws19Thumb from '../assets/storyofsongmin-pws-19-thumb.jpg';
import pws20Thumb from '../assets/storyofsongmin-pws-20-thumb.jpg';
import pws21Thumb from '../assets/storyofsongmin-pws-21-thumb.jpg';
import pws22Thumb from '../assets/storyofsongmin-pws-22-thumb.jpg';
import pws23Thumb from '../assets/storyofsongmin-pws-23-thumb.jpg';
import pws24Thumb from '../assets/storyofsongmin-pws-24-thumb.jpg';
import pws25Thumb from '../assets/storyofsongmin-pws-25-thumb.jpg';
import pws26Thumb from '../assets/storyofsongmin-pws-26-thumb.jpg';
import pws27Thumb from '../assets/storyofsongmin-pws-27-thumb.jpg';
import pws28Thumb from '../assets/storyofsongmin-pws-28-thumb.jpg';
import pws29Thumb from '../assets/storyofsongmin-pws-29-thumb.jpg';
import pws30Thumb from '../assets/storyofsongmin-pws-30-thumb.jpg';
import pws31Thumb from '../assets/storyofsongmin-pws-31-thumb.jpg';
import pws32Thumb from '../assets/storyofsongmin-pws-32-thumb.jpg';
import pws33Thumb from '../assets/storyofsongmin-pws-33-thumb.jpg';
import pws34Thumb from '../assets/storyofsongmin-pws-34-thumb.jpg';
import pws35Thumb from '../assets/storyofsongmin-pws-35-thumb.jpg';
import pws36Thumb from '../assets/storyofsongmin-pws-36-thumb.jpg';
import pws37Thumb from '../assets/storyofsongmin-pws-37-thumb.jpg';
import pws1 from '../assets/storyofsongmin-pws-1.jpg';
import pws2 from '../assets/storyofsongmin-pws-2.jpg';
import pws3 from '../assets/storyofsongmin-pws-3.jpg';
import pws4 from '../assets/storyofsongmin-pws-4.jpg';
import pws5 from '../assets/storyofsongmin-pws-5.jpg';
import pws6 from '../assets/storyofsongmin-pws-6.jpg';
import pws7 from '../assets/storyofsongmin-pws-7.jpg';
import pws8 from '../assets/storyofsongmin-pws-8.jpg';
import pws9 from '../assets/storyofsongmin-pws-9.jpg';
import pws10 from '../assets/storyofsongmin-pws-10.jpg';
import pws11 from '../assets/storyofsongmin-pws-11.jpg';
import pws12 from '../assets/storyofsongmin-pws-12.jpg';
import pws13 from '../assets/storyofsongmin-pws-13.jpg';
import pws14 from '../assets/storyofsongmin-pws-14.jpg';
import pws15 from '../assets/storyofsongmin-pws-15.jpg';
import pws16 from '../assets/storyofsongmin-pws-16.jpg';
import pws17 from '../assets/storyofsongmin-pws-17.jpg';
import pws18 from '../assets/storyofsongmin-pws-18.jpg';
import pws19 from '../assets/storyofsongmin-pws-19.jpg';
import pws20 from '../assets/storyofsongmin-pws-20.jpg';
import pws21 from '../assets/storyofsongmin-pws-21.jpg';
import pws22 from '../assets/storyofsongmin-pws-22.jpg';
import pws23 from '../assets/storyofsongmin-pws-23.jpg';
import pws24 from '../assets/storyofsongmin-pws-24.jpg';
import pws25 from '../assets/storyofsongmin-pws-25.jpg';
import pws26 from '../assets/storyofsongmin-pws-26.jpg';
import pws27 from '../assets/storyofsongmin-pws-27.jpg';
import pws28 from '../assets/storyofsongmin-pws-28.jpg';
import pws29 from '../assets/storyofsongmin-pws-29.jpg';
import pws30 from '../assets/storyofsongmin-pws-30.jpg';
import pws31 from '../assets/storyofsongmin-pws-31.jpg';
import pws32 from '../assets/storyofsongmin-pws-32.jpg';
import pws33 from '../assets/storyofsongmin-pws-33.jpg';
import pws34 from '../assets/storyofsongmin-pws-34.jpg';
import pws35 from '../assets/storyofsongmin-pws-35.jpg';
import pws36 from '../assets/storyofsongmin-pws-36.jpg';
import pws37 from '../assets/storyofsongmin-pws-37.jpg';

function Gallery() {
    const { setLightBoxOpen } = useContext(LightBoxOpenContext);
    const { showLoader, setShowLoader } = useContext(ShowLoaderContext);
    const [index, setIndex] = useState(0);
    let loaded = useRef(0);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [showLoader]);

    const loadImage = () => {
        loaded.current++;

        if (loaded.current === imageList.length) {
            setShowLoader(false)
        }
    }

    function openLightBox(index: number) {
        setIndex(index);
        document.body.style.overflow = 'hidden';
        setLightBoxOpen(true);
    }

    return (
        <>
            <LightBox imageList={ imageList } index={ index } setIndex={ setIndex } />

            <Card className="content-card">
                <Typography
                    variant="h2"
                    noWrap
                    sx={{ display: { xs: 'none', md: 'block' } }}
                    className="h2-text"
                >
                    Gallery
                </Typography>

                <p className="p-text mb-5">
                    Every story has its moments, and here are some of ours. Thank you <a href="https://www.oheystudios.com/" target="_blank" className="anchor" rel="noreferrer">Oheystudios<Icon fontSize="small" className="icon">open_in_new</Icon></a> for capturing these photos of us so splendidly - we had fun doing things we’d never do in day-to-day life, like casually sitting on a ramp at a carpark in the middle of the day :P
                    <br/>
                    <br/>
                    We will subsequently also be uploading photos of our wedding reception onto this page. Do stay
                    tuned!
                </p>

                <Box>
                    <Fade in={ !showLoader } timeout={ 500 } easing={ 'ease-in-out' } style={ showLoader ? { width: 0, height: 0 } : {} }>
                        <Masonry
                            columns={{ xs: 2, md: 4 }}
                            spacing={2}
                            defaultHeight={ 250 }
                            defaultColumns={ 4 }
                            defaultSpacing={ 2 }
                            sx={{ margin: 0 }}
                        >
                            { imageList.map((item, index) => (
                                <Box key={ index } className="masonry-wrapper">
                                    <img
                                        alt=''
                                        src={ `${ item.thumbNail }` }
                                        className="masonry-image"
                                        onLoad={ () => loadImage() }
                                    />

                                    <Box className="image-preview" onClick={ () => openLightBox(index) }>
                                        <Icon fontSize="small" className="preview-icon">visibility</Icon>
                                    </Box>
                                </Box>
                            )) }
                        </Masonry>
                    </Fade>

                    <Box className="masonry-button-wrapper">
                        <Button className="thats-all-button bodoni-font" disabled>
                            That's All!
                        </Button>
                    </Box>
                </Box>
            </Card>
        </>
    );
}

const imageList = [
    {
        key: 1,
        img: pws33,
        thumbNail: pws33Thumb,
    },
    {
        key: 2,
        img: pws23,
        thumbNail: pws23Thumb,
    },
    {
        key: 3,
        img: pws6,
        thumbNail: pws6Thumb,
    },
    {
        key: 4,
        img: pws8,
        thumbNail: pws8Thumb,
    },
    {
        key: 5,
        img: pws10,
        thumbNail: pws10Thumb,
    },
    {
        key: 6,
        img: pws5,
        thumbNail: pws5Thumb,
    },
    {
        key: 7,
        img: pws16,
        thumbNail: pws16Thumb,
    },
    {
        key: 8,
        img: pws26,
        thumbNail: pws26Thumb,
    },
    {
        key: 9,
        img: pws25,
        thumbNail: pws25Thumb,
    },
    {
        key: 10,
        img: pws9,
        thumbNail: pws9Thumb,
    },
    {
        key: 11,
        img: pws14,
        thumbNail: pws14Thumb,
    },
    {
        key: 12,
        img: pws28,
        thumbNail: pws28Thumb,
    },
    {
        key: 13,
        img: pws13,
        thumbNail: pws13Thumb,
    },
    {
        key: 14,
        img: pws11,
        thumbNail: pws11Thumb,
    },
    {
        key: 15,
        img: pws3,
        thumbNail: pws3Thumb,
    },
    {
        key: 16,
        img: pws7,
        thumbNail: pws7Thumb,
    },
    {
        key: 17,
        img: pws20,
        thumbNail: pws20Thumb,
    },
    {
        key: 18,
        img: pws12,
        thumbNail: pws12Thumb,
    },
    {
        key: 19,
        img: pws30,
        thumbNail: pws30Thumb,
    },
    {
        key: 20,
        img: pws37,
        thumbNail: pws37Thumb,
    },
    {
        key: 21,
        img: pws31,
        thumbNail: pws31Thumb,
    },
    {
        key: 22,
        img: pws2,
        thumbNail: pws2Thumb,
    },
    {
        key: 23,
        img: pws35,
        thumbNail: pws35Thumb,
    },
    {
        key: 24,
        img: pws32,
        thumbNail: pws32Thumb,
    },
    {
        key: 25,
        img: pws1,
        thumbNail: pws1Thumb,
    },
    {
        key: 26,
        img: pws27,
        thumbNail: pws27Thumb,
    },
    {
        key: 27,
        img: pws17,
        thumbNail: pws17Thumb,
    },
    {
        key: 28,
        img: pws21,
        thumbNail: pws21Thumb,
    },
    {
        key: 29,
        img: pws34,
        thumbNail: pws34Thumb,
    },
    {
        key: 30,
        img: pws18,
        thumbNail: pws18Thumb,
    },
    {
        key: 31,
        img: pws15,
        thumbNail: pws15Thumb,
    },
    {
        key: 32,
        img: pws22,
        thumbNail: pws22Thumb,
    },
    {
        key: 33,
        img: pws19,
        thumbNail: pws19Thumb,
    },
    {
        key: 34,
        img: pws24,
        thumbNail: pws24Thumb,
    },
    {
        key: 35,
        img: pws29,
        thumbNail: pws29Thumb,
    },
    {
        key: 36,
        img: pws36,
        thumbNail: pws36Thumb,
    },
    {
        key: 37,
        img: pws4,
        thumbNail: pws4Thumb,
    },
];

export default Gallery;