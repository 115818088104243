import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import './failureDialog.scss';
import Icon from "@mui/material/Icon";

function FailureDialog(props: any) {
    const handleClose = () => {
        props.onClose();
    };

    const handleRetry = () => {
        props.onRetry();
    }

    return (
        <>
            <Dialog
                open={ props.open }
                onClose={ handleClose }
            >
                <DialogTitle className="dialog-header">
                    <Icon className="failure-close">cancel</Icon>
                    Oops!
                </DialogTitle>
                <DialogContent className="dialog-content">
                    <p>
                        Something went wrong. If you are encountering this error repeatedly, do try again at a later time.
                    </p>
                </DialogContent>
                <DialogActions className="dialog-footer failure-footer">
                    <Button className="secondary-button" onClick={ handleClose }>Cancel</Button>
                    <Button className="primary-button" onClick={ handleRetry } autoFocus>Retry</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default FailureDialog;