import * as React from 'react';
import { useContext, useEffect, useRef, useState } from "react";
import { useGesture } from "@use-gesture/react";
import Box from "@mui/material/Box";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import Icon from "@mui/material/Icon";

import './light-box.scss';
import { LightBoxOpenContext } from "../../index";

function LightBox(data: any) {
    const isDesktop = useMediaQuery(useTheme().breakpoints.up("md"));
    const { lightBoxOpen, setLightBoxOpen } = useContext(LightBoxOpenContext);
    const [zoomedIn, setZoomedIn] = useState(false);
    const [fullScreened, setFullScreened] = useState(false);
    const [showControls, setShowControls] = useState(true);
    const [waitingClick, setWaitingClick] = useState<any>(null);
    const [lastClick, setLastClick] = useState(0);
    const [imageStyle, setImageStyle] = useState({ x: 0, y: 0, z: 0, scale: 1 });
    const lightBoxRef = useRef<any>();
    const activeImage = useRef<any>();

    useEffect(() => {
        const fullScreenCheck = () => {
            if (document.fullscreenElement) {
                setFullScreened(true);
            } else {
                setFullScreened(false);
            }
        }

        document.body.addEventListener('fullscreenchange', fullScreenCheck);

        return () => {
            document.body.removeEventListener('fullscreenchange', fullScreenCheck);
        }
    }, []);

    useEffect(() => {
        const back = () => {
            if (document.fullscreenElement) {
                document.exitFullscreen().then(() => {});
            }
            document.body.style.overflow = 'unset';
            setLightBoxOpen(false);
        };

        window.addEventListener('popstate', back);
    }, [setLightBoxOpen]);

    useEffect(() => {
        if (!lightBoxOpen) {
            return;
        }

        setShowControls(true);
        setTimeout(() => {
            setShowControls(false);
        }, 3000);
    }, [lightBoxOpen])

    useEffect(() => {
        const lightBox = lightBoxRef.current;
        let timeout: any = null;

        const showControlsFunc = () => {
            setShowControls(true);

            clearTimeout(timeout);

            timeout = setTimeout(() => {
                setShowControls(false);
            }, 3000);
        };

        lightBox.addEventListener('mousedown', showControlsFunc);
        lightBox.addEventListener('mousemove', showControlsFunc);
        lightBox.addEventListener('touchstart', showControlsFunc);

        return () => {
            lightBox.removeEventListener('mousedown', showControlsFunc);
            lightBox.removeEventListener('mousemove', showControlsFunc);
            lightBox.removeEventListener('touchstart', showControlsFunc);
        };
    }, []);

    useGesture({
        onDrag: ({
                     pinching,
                     last,
                     distance: [d],
                     direction: [dir],
                     offset: [x, y],
                     cancel
                 }) => {
            if (!lightBoxOpen || pinching) {
                return cancel();
            }

            if (!zoomedIn) {
                if (last && d >= 50) {
                    if (dir === 1) {
                        prev();
                    } else if (dir === -1) {
                        next();
                    }
                }
            } else {
                activeImage.current.style.transition = '';
                setImageStyle((imageStyle) => ({ ...imageStyle, x, y }));
            }
        },
        onPinch: ({
                      origin: [ox, oy],
                      first,
                      last,
                      da: [d],
                      initial: [id],
                      offset: [angle],
                      memo,
                      cancel
                  }) => {
            if (isDesktop) {
                return cancel();
            }

            if (first) {
                const { width, height, x, y } = activeImage.current.getBoundingClientRect();
                const initialScale = imageStyle.scale;
                const tx = ox - (x + width / 2);
                const ty = oy - (y + height / 2);
                memo = [imageStyle.x, imageStyle.y, tx, ty, initialScale];
            }

            const ms = d / id;
            const x = memo[0] - (ms - 1) * memo[2];
            const y = memo[1] - (ms - 1) * memo[3];
            const z = angle;
            const scale = memo[4] * ms;

            if (last && scale <= 0.5) {
                close();
            } else if (last && scale <= 1) {
                setZoomedIn(false);
                setImageStyle({ x: 0, y: 0, z: 0, scale: 1 });
            } else {
                setZoomedIn(true);
                activeImage.current.style.transition = '';
                setImageStyle({ x, y, z, scale });
            }

            return memo
        }
    }, {
        target: activeImage,
        eventOptions: { passive: false },
        drag: { filterTaps: true, from: () => [imageStyle.x, imageStyle.y] },
        pinch: { threshold: 0.1 }
    });

    function zoom() {
        if (zoomedIn) {
            activeImage.current.style.transition = 'ease-out 0.5s';
            setImageStyle({ x: 0, y: 0, z: 0, scale: 1 });
        } else {
            activeImage.current.style.transition = 'ease-in 0.5s';
            setImageStyle({ x: 0, y: 0, z: 0, scale: 2 });
        }

        setZoomedIn(!zoomedIn);
    }

    function fullScreen() {
        if (document.fullscreenElement) {
            document.exitFullscreen().then(() => {});
        } else {
            document.body.requestFullscreen().then(() => {});
        }
    }

    function prev() {
        reset();

        if (data.index === 0) {
            data.setIndex(data.imageList.length - 1);
        } else {
            data.setIndex(data.index - 1);
        }
    }

    function next() {
        reset();

        if (data.index === data.imageList.length - 1) {
            data.setIndex(0);
        } else {
            data.setIndex(data.index + 1);
        }
    }

    function close(ev?: any) {
        if (ev?.target.classList.contains('lightbox-image') || ((ev?.target.classList.contains('MuiButton-root') || ev?.target.classList.contains('material-icons')) && !ev?.target.classList.contains('close-button-icon')) || ev?.target.classList.contains('counter-text-span')) {
            return;
        }

        reset();
        if (document.fullscreenElement) {
            document.exitFullscreen().then(() => {});
        }
        document.body.style.overflow = 'unset';
        setLightBoxOpen(false);
    }

    function reset() {
        if (zoomedIn) {
            zoom();
        }
        setImageStyle({ x: 0, y: 0, z: 0, scale: 1 });
    }

    function getImageClass(image: any, imageIndex: number) {
        const activeIndex = data.index;
        const lastIndex = data.imageList.length - 1;
        let imageClass = "lightbox-image-wrapper lightbox_image-wrapper-" + imageIndex;

        if (imageIndex === activeIndex) {
            imageClass += " active";
        } else if ((activeIndex !== 0 && activeIndex !== lastIndex && imageIndex < activeIndex) || (activeIndex === 0 && imageIndex === lastIndex) || (activeIndex === lastIndex && imageIndex !== 0)) {
            imageClass += " left-hidden";
        } else {
            imageClass += " right-hidden";
        }

        return imageClass;
    }

    function processClicks(ev?: any) {
        if (lastClick && ev?.timeStamp - lastClick < 250 && waitingClick) {
            setLastClick(0);
            clearTimeout(waitingClick);
            setWaitingClick(null);

            zoom();
        }

        setLastClick(ev?.timeStamp);
        setWaitingClick(setTimeout(() => {
            setWaitingClick(null);
        }, 250));
    }

    function mouseClick(isDown: boolean) {
        if (isDown) {
            activeImage.current.style.cursor = 'grabbing';
        } else {
            activeImage.current.style.cursor = 'grab';
        }
    }

    return (
        <Box id="lightbox" ref={ lightBoxRef } className={ lightBoxOpen ? 'lightbox-show' : 'lightbox-hide' } onClick={ (event) => close(event) }>
            <Box className={ showControls ? "top-buttons-wrapper show" : "top-buttons-wrapper hide" }>
                <Button className="zoom-out-button" id="zoomOutButton" onClick={ () => zoom() }>
                    { zoomedIn ? <Icon fontSize="medium" className="lightbox-icon">zoom_out</Icon> : <Icon fontSize="medium" className="lightbox-icon">zoom_in</Icon> }
                </Button>
                <Button className="full-screen-button" id="fullScreenButton" onClick={ () => fullScreen() }>
                    { fullScreened ? <Icon fontSize="medium" className="lightbox-icon">fullscreen_exit</Icon> : <Icon fontSize="medium" className="lightbox-icon">fullscreen</Icon> }
                </Button>
                <Button id="closeButton" className="close-button" onClick={ () => close() }>
                    <Icon fontSize="medium" className="lightbox-icon close-button-icon">close</Icon>
                </Button>
            </Box>

            <Box className={ showControls ? "nav-buttons-wrapper button-left show" : "nav-buttons-wrapper button-left hide" } style={ isDesktop ? {} : { display: 'none' } }>
                <Button id="prevButton" className="prev-button" onClick={ () => prev() }>
                    <Icon fontSize="medium" className="lightbox-icon">arrow_back</Icon>
                </Button>
            </Box>

            <Box className={ isDesktop ? 'lightbox-content lightbox-content-desktop' : 'lightbox-content lightbox-content-mobile' }>
                { data.imageList.map((image: any, index: number) => (
                    <Box className={ getImageClass(image, index) } key={ index }>
                        <img
                            alt=""
                            ref={ data.index === index ? activeImage : undefined }
                            className={ "lightbox-image lightbox-image-" + index }
                            src={ image.img } draggable={ false }
                            onClick={ (event) => processClicks(event) }
                            onMouseDown={ () => mouseClick(true) }
                            onMouseUp={ () => mouseClick(false) }
                            style={
                                data.index === index ?
                                    {
                                        transform: `translate3d(${ imageStyle.x }px, ${ imageStyle.y }px, 0) scale(${ imageStyle.scale })`
                                    } :
                                    {}
                            }
                        />
                    </Box>
                )) }
            </Box>

            <Box className={ showControls ? "nav-buttons-wrapper button-right show" : "nav-buttons-wrapper button-right hide" } style={ isDesktop ? {} : { display: 'none' } }>
                <Button id="nextButton" className="next-button" onClick={ () => next() }>
                    <Icon fontSize="medium" className="lightbox-icon">arrow_forward</Icon>
                </Button>
            </Box>

            <Box className={ showControls ? "counter-text show" : "counter-text hide" }>
                <span className="counter-text-span">
                    { data.index + 1 } / { data.imageList.length }
                </span>
            </Box>
        </Box>
    );
}
export default LightBox;