import * as React from 'react';
import { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
    Card,
    styled,
    useMediaQuery,
    useTheme
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Icon from "@mui/material/Icon";
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

import './details.scss';
import { ShowLoaderContext } from "../index";

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={ 0 } square { ...props }  children={ props.children }/>
))(() => ({
    border: `1px solid #dee2e6`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={ <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} /> }
        { ...props }
    />
))(() => ({
    backgroundColor: '#FDF4EC',
    color: '#495057',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        margin: '16px 0 16px 12px',
    },
    '&:hover': {
        backgroundColor: '#F1E1D3',
    },
    'p': {
        fontWeight: 600,
        margin: '0 !important',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
    padding: '1rem',
    borderTop: '1px solid #dee2e6',
    color: '#495057',
    'p': {
        margin: '0 !important',
    },
}));

function Details() {
    const { showLoader, setShowLoader } = useContext(ShowLoaderContext);
    const isDesktop = useMediaQuery(useTheme().breakpoints.up("md"));
    const [expanded, setExpanded] = useState<string | false>(false);

    useEffect(() => {
        setShowLoader(false);
    }, [setShowLoader]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [showLoader]);

    function scrollTo(id: string) {
        setTimeout(()=>{
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block:  'start' });
            }
        }, 500);
    }

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
        scrollTo(panel);
    };

    return (
        <>
            <Card className="content-card">
                <Typography
                    variant="h2"
                    noWrap
                    sx={{ display: { xs: 'none', md: 'block' } }}
                    className="h2-text"
                >
                    Details
                </Typography>

                <p className="p-text mb-5">
                    On this page you should find most of what you will need to know about <i>The Story of Songmin</i>. If you have questions that are not addressed in the <span className="anchor" onClick={ () => scrollTo('faq') }>FAQs</span>, feel free to <NavLink className="anchor" to={ '/contact' }>contact us</NavLink>.
                </p>

                <Box className={ isDesktop ? "details-wrapper details-wrapper-desktop" : "details-wrapper details-wrapper-mobile" }>
                    <Box className={ isDesktop ? "details-item details-item-desktop" : "details-item details-item-mobile" }>
                        <Box className="details-item-icon-wrapper">
                            <Icon className="details-item-icon">groups</Icon>
                        </Box>
                        <Box className="details-item-label-wrapper">
                            <label className="label details-item-label">YOUR HOSTS</label>
                            <hr className="hr" />
                        </Box>
                        <p className="p-text details-item-text m-0">
                            <strong>Lin Xu Song & Chua Si Min</strong>
                            <br/>
                            Son of Liu Xiao Hong, & daughter of Chua Hua Heng and Lim Beng Kim
                        </p>
                    </Box>

                    <Box className={ isDesktop ? "details-item details-item-desktop" : "details-item details-item-mobile mt-5" }>
                        <Box className="details-item-icon-wrapper">
                            <Icon className="details-item-icon">pin_drop</Icon>
                        </Box>
                        <Box className="details-item-label-wrapper">
                            <label className="label details-item-label">VENUE</label>
                            <hr className="hr" />
                        </Box>
                        <p className="p-text details-item-text m-0">
                            <strong>Conrad Centennial Singapore</strong>
                            <br />
                            The Pavilion, Level 4
                            <br />
                            2 Temasek Boulevard
                            <br />
                            Singapore 038982
                        </p>
                    </Box>

                    <Box className={ isDesktop ? "details-item details-item-desktop" : "details-item details-item-mobile mt-5" }>
                        <Box className="details-item-icon-wrapper">
                            <Icon className="details-item-icon">event</Icon>
                        </Box>
                        <Box className="details-item-label-wrapper">
                            <label className="label details-item-label">DATE AND TIME</label>
                            <hr className="hr" />
                        </Box>
                        <p className="p-text details-item-text m-0">
                            <strong>Sat, 10 September 2022</strong>
                            <br />
                            12:30 PM
                            <br />
                            <i>Cocktail reception at 12:00 PM</i>
                        </p>
                    </Box>

                    <Box className={ isDesktop ? "details-item details-item-desktop" : "details-item details-item-mobile mt-5" }>
                        <Box className="details-item-icon-wrapper">
                            <Icon className="details-item-icon">checkroom</Icon>
                        </Box>
                        <Box className="details-item-label-wrapper">
                            <label className="label details-item-label">DRESS CODE</label>
                            <hr className="hr" />
                        </Box>
                        <p className="p-text details-item-text m-0">
                            <strong>Semiformal</strong>
                            <br />
                            e.g. cocktail dresses, suit jackets, dress shoes
                        </p>
                    </Box>
                </Box>

                <Box className="location-label-wrapper">
                    <Box sx={{ display: 'inline-block' }}>
                        <hr className="hr line"/>
                        <label className="label location-label">MAP</label>
                    </Box>
                </Box>

                <Box className={ isDesktop ? "maps-desktop" : "maps-mobile" }>
                    <iframe
                        title="maps"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.8014732776564!2d103.85671301587115!3d1.2935943621111974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19a8d72c6c0f%3A0xeaf915f71902e573!2sConrad%20Centennial%20Singapore!5e0!3m2!1sen!2ssg!4v1657875248719!5m2!1sen!2ssg"
                        width="100%"
                        height="100%"
                        style={{ border: 0 }}
                        loading="lazy"
                    >
                    </iframe>
                </Box>

                <Box className="location-label-wrapper" id="faq" sx={{ marginTop: '3rem' }}>
                    <Box sx={{ display: 'inline-block' }}>
                        <hr className="hr line"/>
                        <label className="label location-label">FREQUENTLY-ASKED QUESTIONS</label>
                    </Box>
                </Box>

                <Box>
                    <Accordion expanded={ expanded === 'faq1' } onChange={ handleChange('faq1') }>
                        <AccordionSummary aria-controls="faq1-content" id="faq1">
                            <Box className="p-text m-0">
                                When is the RSVP deadline?
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box className="p-text m-0">
                                Please RSVP by <strong>Friday, 12 August 2022</strong>, so that we may have sufficient time to work with our venue to arrange the necessary.
                            </Box>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={ expanded === 'faq2' } onChange={ handleChange('faq2') }>
                        <AccordionSummary aria-controls="faq2-content" id="faq2">
                            <Box className="p-text m-0">
                                Can I bring a plus one or my child?
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box className="p-text m-0">
                                While this will be subject to vacancies due to our venue’s limited capacity, we’d be glad to try to make arrangements! Please <NavLink className="anchor" to={ '/contact' }>contact us</NavLink> with the details. If you are thinking of bringing your child, do also let us know if he or she will require a catered meal.
                            </Box>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={ expanded === 'faq3' } onChange={ handleChange('faq3') }>
                        <AccordionSummary aria-controls="faq3-content" id="faq3">
                            <Box className="p-text m-0">
                                How do I get to the venue?
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box className="p-text m-0">
                                <p className="p-text text-decoration-underline m-0"><strong>By car</strong></p>
                                <p className="p-text m-0">If you will be dropped off, Conrad Centennial Singapore has a dedicated drop-off
                                    point in front of their lobby, accessible via Temasek Boulevard. Carpark spaces are
                                    available at the hotel basement. Alternative carparks in the vicinity of the hotel
                                    include Marina Square, Milennia Walk, and Suntec Singapore Convention and Exhibition
                                    Centre.</p>
                                <br/>
                                <p className="p-text text-decoration-underline m-0"><strong>By public transport</strong></p>
                                <p className="p-text m-0">If you are taking the MRT, alight at Promenade station (CC4/DT15), and exit through
                                    Exit B. Buses in the vicinity include 36, 36A, 70A, 70M, 97, 97E, 106, 111, 133,
                                    162M, 502, 502A, 518, 518A, 857, and 868E.</p>
                            </Box>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={ expanded === 'faq4' } onChange={ handleChange('faq4') }>
                        <AccordionSummary aria-controls="faq4-content" id="faq4">
                            <Box className="p-text m-0">
                                Should I dress according to a theme?
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box className="p-text m-0">
                                Our dress code is ‘semiformal’, e.g. cocktail dresses, suit jackets and dress shoes. As for colour and theme, we have no specifics :)
                            </Box>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={ expanded === 'faq5' } onChange={ handleChange('faq5') }>
                        <AccordionSummary aria-controls="faq5-content" id="faq5">
                            <Box className="p-text m-0">
                                Will there be any special diet meal options, e.g. Halal, vegan, gluten free etc.?
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box className="p-text m-0">
                                The hotel provides Chinese Vegetarian, Indian Vegetarian, and Muslim (No Pork, No Lard) meal options. Should you have other dietary restrictions or preferences, please select ‘Others’ in the <NavLink className="anchor" to={ '/rsvp' }>RSVP page</NavLink> and specify accordingly, e.g. “shellfish allergy”. We will try our best to make the necessary arrangements.
                            </Box>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={ expanded === 'faq6' } onChange={ handleChange('faq6') }>
                        <AccordionSummary aria-controls="faq6-content" id="faq6">
                            <Box className="p-text m-0">
                                I am a family member and will be attending the tea ceremony. Where will it be held at, and what time should I be there by?
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box className="p-text m-0">
                                The tea ceremony will be held at a <strong>function room</strong> (Salon II) at Conrad Centennial Singapore, which is on the second floor of the hotel. It will commence in the morning at <strong>11:00 AM</strong>. Upon arrival, you will be directed to the location by either the hotel’s staff, or the friendly members of our bridal party :)
                            </Box>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={ expanded === 'faq7' } onChange={ handleChange('faq7') }>
                        <AccordionSummary aria-controls="faq7-content" id="faq7">
                            <Box className="p-text m-0">
                                What should I do if I cannot attend?
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box className="p-text m-0">
                                You will be missed ): Please let us know asap by indicating ‘No’ accordingly in the <NavLink className="anchor" to={ '/rsvp' }>RSVP page</NavLink>. If you had received an invitation from us, it means you had played a significant part of our lives in some way or another, and we’d like to thank you regardless!
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box>

                <Box className={ isDesktop ? "fun-fact-item fun-fact-item-desktop" : "fun-fact-item fun-fact-item-mobile" }>
                    <Box className="fun-fact-item-icon-wrapper-wrapper">
                        <Box className="fun-fact-item-icon-wrapper" style={{ marginRight: '0.25rem' }}>
                            <Icon className="fun-fact-item-icon">draw</Icon>
                        </Box>
                        <Box className="fun-fact-item-icon-wrapper" style={{ marginLeft: '0.25rem' }}>
                            <Icon className="fun-fact-item-icon">code</Icon>
                        </Box>
                    </Box>
                    <Box className="fun-fact-item-label-wrapper">
                        <label className="label fun-fact-item-label">FUN FACT</label>
                        <hr className="hr" />
                    </Box>
                    <p className="p-text fun-fact-item-text m-0">
                        This website is proudly designed and coded in-house by the two of us! We had fun applying the UX designer and fullstack developer personas that we adopt at work to one of the biggest events of our lives. Do <NavLink className="anchor" to={ '/contact' }>let us know</NavLink> if you think this website could be improved in any way :)
                    </p>
                </Box>
            </Card>
        </>
    );
}
export default Details;