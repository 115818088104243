import * as React from 'react';
import './home.scss';
import { useContext, useEffect, useState } from "react";
import { animateScroll, Events } from "react-scroll";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Card, Fade, useMediaQuery, useTheme } from "@mui/material";
import { NavLink } from "react-router-dom";
import Typography from "@mui/material/Typography";

import kv from "../assets/kv-storyofsongmin.png";
import logo from "../assets/logo-storyofsongmin.png";
import polaroidFrame from "../assets/polaroid-middle.png";
import polaroidPhoto1 from "../assets/polaroid-1.jpg";
import polaroidPhoto2 from "../assets/polaroid-2.jpg";
import polaroidPhoto3 from "../assets/polaroid-3.jpg";
import { ShowLoaderContext, ToolbarHiddenContext } from "../index";

const assets = [
    kv,
    logo,
    polaroidFrame,
    polaroidPhoto1,
    polaroidPhoto2,
    polaroidPhoto3
];

function Home() {
    const { setToolbarHidden } = useContext(ToolbarHiddenContext);
    const { showLoader, setShowLoader } = useContext(ShowLoaderContext);
    const isDesktop = useMediaQuery(useTheme().breakpoints.up("md"));
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [scrollBegan, setScrollBegan] = useState(false);
    const [scrollEnded, setScrollEnded] = useState(false);
    const [retryToggle, setRetryToggle] = useState(false);

    useEffect(() => {
        for (let i = 0; i < assets.length; i++) {
            let img = new Image();
            img.src = assets[i];
            img.onload = () => {
                if (assets.length - 1 === i) {
                    setShowLoader(false);
                }
            };
        }
    }, [setShowLoader]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [showLoader]);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return { width, height };
    }

    useEffect(() => {
        Events.scrollEvent.register('begin', () => {
            setScrollBegan(true);
        });

        return () => Events.scrollEvent.remove('begin');
    }, []);

    useEffect(() => {
        Events.scrollEvent.register('end', () => {
            setScrollEnded(true);
            setToolbarHidden(false);
        });

        return () => Events.scrollEvent.remove('end');
    }, [setToolbarHidden]);

    useEffect(() => {
        if (isDesktop || scrollBegan || scrollEnded) {
            return;
        }

        if (document.scrollingElement) {
            const scrollBy = document.scrollingElement.scrollHeight - windowDimensions.height;

            if (document.scrollingElement.scrollTop === 0 && scrollBy > 200) {
                animateScroll.scrollTo(scrollBy, {
                    duration: 2000,
                    smooth: 'easeInOutCubic'
                });
                return;
            }
        }

        setRetryToggle(!retryToggle);
    }, [isDesktop, retryToggle, scrollBegan, scrollEnded, windowDimensions.height]);

    return (
        <>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <Fade in={ true } timeout={ 1000 } easing={ 'ease-out' }>
                    <Box className="home-container" sx={{ padding: '24px', height: 'calc(100vh - 276px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Box className="book-container">
                            <img alt="" src={ kv } style={{ width: '100%' }} />
                        </Box>

                        <Box className="polaroids-text-container">
                            <Box className="polaroids-container">
                                <Card className="polaroid-card card-1">
                                    <Box className="polaroid-card-photo-container">
                                        <img alt="" className="polaroid-photo" src={ polaroidPhoto1 } />
                                        <img alt="" className="polaroid-frame" src={ polaroidFrame } />
                                    </Box>
                                </Card>

                                <Card className="polaroid-card card-2">
                                    <Box className="polaroid-card-photo-container">
                                        <img alt="" className="polaroid-photo" src={ polaroidPhoto2 } />
                                        <img alt="" className="polaroid-frame" src={ polaroidFrame } />
                                    </Box>
                                </Card>

                                <Card className="polaroid-card card-3">
                                    <Box className="polaroid-card-photo-container">
                                        <img alt="" className="polaroid-photo" src={ polaroidPhoto3 } />
                                        <img alt="" className="polaroid-frame" src={ polaroidFrame } />
                                    </Box>
                                </Card>
                            </Box>

                            <Box className="polaroids-text">
                                <Typography className="our-text">Flip the pages.</Typography>
                                <Button
                                    className="join-us-button"
                                    component={ NavLink }
                                    key={ 'RSVP' }
                                    to={ '/rsvp' }
                                >
                                    Join Us
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            </Box>

            <Box className="home-container-mobile" sx={{ display: { xs: 'block', md: 'none' } }}>
                <Box className="book-container-mobile">
                    <img alt="" src={ kv } width="110%" />
                </Box>

                <Box className="polaroids-container-mobile">
                    <Card className="polaroid-card card-1-mobile">
                        <Box className="polaroid-card-photo-container">
                            <img alt="" className="polaroid-photo" src={ polaroidPhoto1 } />
                            <img alt="" className="polaroid-frame" src={ polaroidFrame } />
                        </Box>
                    </Card>

                    <Card className="polaroid-card card-2-mobile">
                        <Box className="polaroid-card-photo-container">
                            <img alt="" className="polaroid-photo" src={ polaroidPhoto2 } />
                            <img alt="" className="polaroid-frame" src={ polaroidFrame } />
                        </Box>
                    </Card>

                    <Card id="scroll-to" className="logo-container">
                        <Box className="logo-photo-container">
                            <img alt="" className="logo-photo" src={ logo } />
                        </Box>
                    </Card>

                    <Card className="polaroid-card card-3-mobile">
                        <Box className="polaroid-card-photo-container">
                            <img alt="" className="polaroid-photo" src={ polaroidPhoto3 } />
                            <img alt="" className="polaroid-frame" src={ polaroidFrame } />
                        </Box>
                    </Card>
                </Box>

                <Fade in={ scrollEnded } timeout={ 1000 } easing={ 'ease-out' }>
                    <Box className="rsvp-button-container">
                        <Typography className="our-text">Flip the pages.</Typography>
                        <Button
                            className="rsvp-button-home bodoni-font bodoni-font-mobile"
                            component={ NavLink }
                            key={ 'RSVP' }
                            to={ '/rsvp' }
                        >
                            Join Us
                        </Button>
                    </Box>
                </Fade>
            </Box>
        </>
    );
}
export default Home;