import * as React from "react";
import { createContext, Dispatch, SetStateAction, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Navigate, NavLink, Outlet, Route, Routes } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import Box from "@mui/material/Box";
import { Drawer, useMediaQuery, useTheme } from "@mui/material";
import Button from "@mui/material/Button";

import './index.scss';
import background from "./assets/bg-wood-2.jpg";
import Container from "@mui/material/Container";
import pen from "./assets/bg-pen.png";
import logo from "./assets/logo-storyofsongmin.png";
import spinner from "./assets/spinner.gif";
import App from './App';
import Home from "./home/home";
import Gallery from "./gallery/gallery";
import Details from "./details/details";
import Contact from "./contact/contact";
import RSVP from "./rsvp/rsvp";

interface IToolbarHiddenContext {
    toolbarHidden: boolean;
    setToolbarHidden: Dispatch<SetStateAction<boolean>>;
}

interface ILightBoxOpenContext {
    lightBoxOpen: boolean;
    setLightBoxOpen: Dispatch<SetStateAction<boolean>>;
}

interface IShowLoaderContext {
    showLoader: boolean;
    setShowLoader: Dispatch<SetStateAction<boolean>>;
}

export const ToolbarHiddenContext = createContext<IToolbarHiddenContext>({
    toolbarHidden: false,
    setToolbarHidden: () => {}
});

export const LightBoxOpenContext = createContext<ILightBoxOpenContext>({
    lightBoxOpen: false,
    setLightBoxOpen: () => {}
});

export const ShowLoaderContext = createContext<IShowLoaderContext>({
    showLoader: false,
    setShowLoader: () => {}
});

export const pages = [
    { text: 'Home', path: '/home' },
    { text: 'Gallery', path: '/gallery' },
    { text: 'Details', path: '/details' },
    { text: 'Contact', path: '/contact' }
];

function AppRoot() {
    const isDesktop = useMediaQuery(useTheme().breakpoints.up("md"));
    const [toolbarHidden, setToolbarHidden] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [lightBoxOpen, setLightBoxOpen] = useState(false);
    const [showLoader, setShowLoader] = useState(true);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            lightBoxOpen || isDesktop ||
            (
                event &&
                event.type === 'keydown' &&
                (
                    (event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift'
                )
            )
        ) {
            return;
        }

        setDrawerOpen(open);
        setToolbarHidden(false);
    };

    return (
        <ToolbarHiddenContext.Provider value={{ toolbarHidden, setToolbarHidden }}>
            <LightBoxOpenContext.Provider value={{ lightBoxOpen, setLightBoxOpen }}>
                <ShowLoaderContext.Provider value={{ showLoader, setShowLoader }}>
                    <Box className="loader" sx={ showLoader ? {} : { display: 'none' } }>
                        <img alt="" src={ spinner } height={ "20%" } />
                    </Box>

                    <Box sx={ showLoader ? { width: 0, height: 0 } : {} }>
                        <Router>
                            <Box sx={ showLoader ? { display: 'none' } : {} }>
                                <App toggleDrawer={ toggleDrawer } />
                            </Box>

                            <Box sx={{ backgroundImage: `url( ${ background } )`, backgroundSize: '100%' }}>
                                <Container maxWidth="xl" sx={{ height: '100%', padding: { xs: '64px 0 0 0', md: '100px 0 0 0' } }}>
                                    <Box sx={{ padding: { xs: '16px', md: '64px 16px' }, minHeight: { xs: 'calc(100vh - 96px)', md: 'calc(100vh - 228px)' } }}>
                                        <Routes>
                                            <Route path="/" element={<Navigate to='/home' />} />
                                            <Route path="/home" element={<Home />} />
                                            <Route path="/gallery" element={<Gallery />} />
                                            <Route path="/details" element={<Details />} />
                                            <Route path="/contact" element={<Contact />} />
                                            <Route path="/rsvp" element={<RSVP />} />
                                        </Routes>

                                        <Outlet />

                                        <Box className="pen-container" sx={{ display: { xs: 'block', md: 'none' } }}>
                                            <img alt="" className="pen" src={ pen } width={ '100%' } />
                                        </Box>
                                    </Box>
                                </Container>
                            </Box>

                            <Drawer
                                anchor={ 'left' }
                                open={ drawerOpen }
                                onClose={ toggleDrawer(false) }
                            >
                                <Box
                                    role="presentation"
                                    onClick={ toggleDrawer(false) }
                                    onKeyDown={ toggleDrawer(false) }
                                    className="sidenav-drawer"
                                >
                                    <Box className="sidenav-drawer-container">
                                        <Box sx={{ display: 'flex', mb: '2rem' }}>
                                            <NavLink to="/home">
                                                <img alt="" src={ logo } style={{ width: '100%' }} />
                                            </NavLink>
                                        </Box>

                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                            { pages.map((page) => (
                                                <Button
                                                    component={ NavLink }
                                                    key={ page.text }
                                                    to={ page.path }
                                                    className="router-list-buttons text-color bodoni-font bodoni-font-mobile"
                                                >
                                                    { page.text }
                                                </Button>
                                            )) }
                                        </Box>

                                        <hr className="hr" style={{ margin: '1rem 0' }} />

                                        <Button
                                            component={ NavLink }
                                            key={ 'RSVP' }
                                            to={ '/rsvp' }
                                            className="rsvp-button-mobile bodoni-font bodoni-font-mobile"
                                        >
                                            RSVP
                                        </Button>
                                    </Box>
                                </Box>
                            </Drawer>
                        </Router>
                    </Box>
                </ShowLoaderContext.Provider>
            </LightBoxOpenContext.Provider>
        </ToolbarHiddenContext.Provider>
    );
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<AppRoot />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
