import * as React from 'react';
import { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
    Box,
    Card,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputAdornment,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    useMediaQuery,
    useTheme
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";

import './rsvp.scss';
import { ShowLoaderContext } from "../index";
import ConfirmDialog from "../dialogs/confirmDialog/confirmDialog";
import SuccessDialog from "../dialogs/successDialog/successDialog";
import FailureDialog from "../dialogs/failureDialog/failureDialog";

function RSVP() {
    const { showLoader, setShowLoader } = useContext(ShowLoaderContext);
    const isDesktop = useMediaQuery(useTheme().breakpoints.up("md"));
    const [disableForm, setDisableForm] = useState(true);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [failureDialogOpen, setFailureDialogOpen] = useState(false);
    const [formValues, setFormValues] = useState<any>({
        fullName: {
            value: '',
            error: false
        },
        relationship: {
            value: '',
            error: false
        },
        rsvp: {
            value: '',
            error: false
        },
        dietaryPreference: {
            value: '',
            show: false,
            error: false
        },
        otherDietaryPreference: {
            value: '',
            show: false,
            error: false
        },
        requireParking: {
            value: '',
            show: false,
            error: false
        },
        contactNo: {
            value: '',
            errorRequired: false,
            errorNumbers: false,
            errorValid: false
        },
        email: {
            value: '',
            show: false,
            error: false
        },
        messages: {
            value: ''
        }
    });
    const relationshipValues = [
        "Si Min's relative",
        "Si Min's friend",
        "Si Min's friend's partner",
        "Song's relative",
        "Song's friend",
        "Song's friend's partner"
    ];

    useEffect(() => {
        setShowLoader(false);
    }, [setShowLoader]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [showLoader]);

    function handleChange(name: string, value: string) {
        const newValues = { ...formValues };
        let error = false;

        switch (name) {
            case "fullName":
                newValues.fullName.value = value;
                newValues.fullName.error = newValues.fullName.value.length === 0;
                if (newValues.fullName.error) {
                    error = true;
                }
                break;
            case "relationship":
                newValues.relationship.value = value;
                newValues.relationship.error = newValues.relationship.value.length === 0;
                if (newValues.relationship.error) {
                    error = true;
                }
                break;
            case "rsvp":
                newValues.rsvp.value = value;
                newValues.rsvp.error = newValues.rsvp.value.length === 0;
                if (newValues.rsvp.error) {
                    error = true;
                }
                newValues.dietaryPreference.show = newValues.rsvp.value === 'Y';
                newValues.requireParking.show = newValues.rsvp.value === 'Y';
                newValues.email.show = newValues.rsvp.value === 'Y';
                if (!newValues.dietaryPreference.show) {
                    newValues.dietaryPreference.value = '';
                    newValues.dietaryPreference.error = false;
                    newValues.otherDietaryPreference.show = false;
                    newValues.otherDietaryPreference.value = '';
                    newValues.otherDietaryPreference.error = false;
                }
                if (!newValues.requireParking.show) {
                    newValues.requireParking.value = '';
                    newValues.requireParking.error = false;
                }
                if (!newValues.email.show) {
                    newValues.email.value = '';
                    newValues.email.error = false;
                }
                break;
            case "dietaryPreference":
                newValues.dietaryPreference.value = value;
                newValues.dietaryPreference.error = newValues.dietaryPreference.show && newValues.dietaryPreference.value.length === 0;
                if (newValues.dietaryPreference.error) {
                    error = true;
                }
                newValues.otherDietaryPreference.show = newValues.dietaryPreference.value === 'Others';
                if (!newValues.otherDietaryPreference.show) {
                    newValues.otherDietaryPreference.value = '';
                    newValues.otherDietaryPreference.error = false;
                }
                break;
            case "otherDietaryPreference":
                newValues.otherDietaryPreference.value = value;
                newValues.otherDietaryPreference.error = newValues.otherDietaryPreference.show && newValues.otherDietaryPreference.value.length === 0;
                if (newValues.otherDietaryPreference.error) {
                    error = true;
                }
                break;
            case "requireParking":
                newValues.requireParking.value = value;
                newValues.requireParking.error = newValues.requireParking.show && newValues.requireParking.value.length === 0;
                if (newValues.requireParking.error) {
                    error = true;
                }
                break;
            case "contactNo":
                const containNumbersOnly = /^[0-9]*$/i.test(value);
                if (containNumbersOnly) {
                    newValues.contactNo.value = value;
                }
                const valid = (newValues.contactNo.value[0] === '9' || newValues.contactNo.value[0] === '8' || newValues.contactNo.value[0] === '6') && newValues.contactNo.value.length === 8;

                newValues.contactNo.errorRequired = newValues.contactNo.value.length === 0;
                newValues.contactNo.errorNumbers = !containNumbersOnly;
                newValues.contactNo.errorValid = newValues.contactNo.value.length > 0 && !valid;

                if (newValues.contactNo.errorRequired || newValues.contactNo.errorNumbers || newValues.contactNo.errorValid) {
                    error = true;
                }
                break;
            case "email":
                newValues.email.value = value;
                newValues.email.error = newValues.email.show && newValues.email.value.length > 0 && !(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(newValues.email.value));
                if (newValues.email.error) {
                    error = true;
                }
                break;
            case "messages":
                newValues.messages.value = value;
                break;
        }

        setFormValues(newValues);

        return error;
    }

    function submit(e?: React.FormEvent<HTMLFormElement>) {
        e?.preventDefault();

        let hasError = false;
        const formFields = Object.keys(formValues);

        formFields.forEach(currentField => {
            const currentValue = formValues[currentField].value;
            const error = handleChange(currentField, currentValue);

            if (error) {
                hasError = true;
            }
        });

        if (!hasError) {
            setConfirmDialogOpen(true);
        }
    }

    function onDialogClose() {
        setConfirmDialogOpen(false);
        setSuccessDialogOpen(false);
        setFailureDialogOpen(false);
    }

    function onConfirmDialogSubmit() {
        setConfirmDialogOpen(false);
        setDisableForm(true);

        const data = {
            fullName: formValues.fullName.value,
            relationship: formValues.relationship.value,
            rsvp: formValues.rsvp.value,
            dietaryPreference: formValues.dietaryPreference.value,
            otherDietaryPreference: formValues.otherDietaryPreference.value,
            requireParking: formValues.requireParking.value,
            contactNo: formValues.contactNo.value,
            email: formValues.email.value,
            messages: formValues.messages.value
        };

        fetch('https://rsvp.storyofsongmin.com/api/saveRsvp', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                if (response.status === 200) {
                    setSuccessDialogOpen(true);
                } else {
                    setFailureDialogOpen(true);
                }
            })
            .catch((err) => {
                console.log(err.message);
                setFailureDialogOpen(true);
            });
    }

    function onSuccessDialogResubmit() {
        setSuccessDialogOpen(false);
        setFormValues({
            fullName: {
                value: '',
                error: false
            },
            relationship: {
                value: '',
                error: false
            },
            rsvp: {
                value: '',
                error: false
            },
            dietaryPreference: {
                value: '',
                show: false,
                error: false
            },
            otherDietaryPreference: {
                value: '',
                show: false,
                error: false
            },
            requireParking: {
                value: '',
                show: false,
                error: false
            },
            contactNo: {
                value: '',
                errorRequired: false,
                errorNumbers: false,
                errorValid: false
            },
            email: {
                value: '',
                show: false,
                error: false
            },
            messages: {
                value: ''
            }
        });
        setDisableForm(false);
    }

    function onFailureDialogRetry() {
        setFailureDialogOpen(false);
        submit();
    }

    return (
        <>
            <Card className="content-card">
                <Typography
                    variant="h2"
                    noWrap
                    sx={{ display: { xs: 'none', md: 'block' } }}
                    className="h2-text"
                >
                    RSVP
                </Typography>

                { disableForm ? <p className="p-text mb-5">Thank you for submitting your RSVP, this form is now <span style={{ fontWeight: 700 }}>closed</span>. We look forward to seeing you! If you’d like more details or have questions, do check out <NavLink className="anchor" to={ '/details' }>this page</NavLink>.</p> : <p className="p-text mb-5">We look forward to having you be a part of our story. Please RSVP by <span style={{ fontWeight: 700 }}>Friday, 12 August 2022</span>. If you’d like more details or have questions, do check out <NavLink className="anchor" to={ '/details' }>this page</NavLink>.</p> }

                <form className="rsvp-form" onSubmit={ (event) => submit(event) } style={ isDesktop ? {} : { width: '100%' } }>
                    <fieldset className="fieldset" disabled={ disableForm }>
                        <FormControl className="form-wrapper">
                            <FormLabel htmlFor="fullName" className="form-label" sx={{ marginBottom: '0.5rem' }}>Full Name <span className="red-text">*</span></FormLabel>
                            <small className="help-text">Please include your last name as we have guests with the same first name!</small>
                            <TextField id="fullName" name="fullName" className="form-input" type="text" placeholder="e.g. Tan Ah Kau" aria-describedby="fullName-help" value={ formValues.fullName.value } error= { formValues.fullName.error } onChange={ e => handleChange(e.target.name, e.target.value) } onBlur={ e => handleChange(e.target.name, e.target.value) } disabled={ disableForm }></TextField>
                            <small id="fullName-help" className={ formValues.fullName.error ? "form-small-text d-flex" : "form-small-text d-none" }>
                                <Icon className="form-icon" fontSize="small">error_outline</Icon>
                                <span>We need your <strong style={{ display: 'inline-block' }}>name</strong>.</span>
                            </small>
                        </FormControl>

                        <FormControl className="form-wrapper">
                            <FormLabel htmlFor="relationship" className="form-label" sx={{ marginBottom: '0.5rem' }}>Relation to Us <span className="red-text">*</span></FormLabel>
                            <small className="help-text">Filling up this field eases our admin processes later. If you consider yourself to be a friend of us both, then just <i>tikam</i> :)</small>
                            <Select
                                id="relationship"
                                name="relationship"
                                className="form-dropdown"
                                aria-describedby="relationship-help"
                                value={ formValues.relationship.value }
                                error={ formValues.relationship.error }
                                onChange={ (event) => handleChange(event.target.name, event.target.value) }
                                onBlur={ e => handleChange(e.target.name, e.target.value) }
                                inputProps={{ 'aria-label': 'Without label' }}
                                size="small"
                                displayEmpty
                                sx={{ width: '100%' }}
                                renderValue={(value) => {
                                    if (!value) {
                                        return <span style={{ opacity: 0.42 }}>Please select</span>;
                                    }

                                    return value;
                                }}
                                disabled={ disableForm }
                            >
                                { relationshipValues.map((option) => (
                                    <MenuItem key={option} value={ option }>{ option }</MenuItem>
                                ))}
                            </Select>
                            <small id="relationship-help" className={ formValues.relationship.error ? "form-small-text d-flex" : "form-small-text d-none" }>
                                <Icon className="form-icon" fontSize="small">error_outline</Icon>
                                <span>Do let us know if you are <strong style={{ display: 'inline-block' }}>#TeamGroom</strong> or <strong style={{ display: 'inline-block' }}>#TeamBride</strong>.</span>
                            </small>
                        </FormControl>

                        <FormControl className="form-wrapper" error={ formValues.rsvp.error } disabled={ disableForm }>
                            <FormLabel htmlFor="rsvp" className="form-label" sx={{ marginBottom: '0.5rem' }}>RSVP <span className="red-text">*</span></FormLabel>
                            <RadioGroup
                                id="rsvp"
                                name="rsvp"
                                className="form-radio-group"
                                value={ formValues.rsvp.value }
                                onChange={ (event) => handleChange(event.target.name, event.target.value) }
                            >
                                <FormControlLabel value="Y" control={<Radio />} label="Yes, I would like to attend." />
                                <FormControlLabel value="N" control={<Radio />} label="No, I will not be attending." />
                            </RadioGroup>
                            <small id="rsvp-help" className={ formValues.rsvp.error ? "form-small-text d-flex" : "form-small-text d-none" } style={{ marginTop: '0.5rem' }}>
                                <Icon className="form-icon" fontSize="small">error_outline</Icon>
                                <span>This field is <strong style={{ display: 'inline-block' }}>required</strong>.</span>
                            </small>
                        </FormControl>

                        <FormControl className="form-wrapper" error={ formValues.dietaryPreference.error } disabled={ disableForm } style={ formValues.dietaryPreference.show ? { display: 'block' } : { display: 'none' } }>
                            <FormLabel htmlFor="dietaryPreference" className="form-label" sx={{ marginBottom: '0.5rem' }}>Dietary Preference <span className="red-text">*</span></FormLabel>
                            <RadioGroup
                                id="dietaryPreference"
                                name="dietaryPreference"
                                className="form-radio-group"
                                value={ formValues.dietaryPreference.value }
                                onChange={ (event) => handleChange(event.target.name, event.target.value) }
                            >
                                <FormControlLabel value="No Preference" control={<Radio />} label="No Preference" />
                                <FormControlLabel value="Chinese Vegetarian" control={<Radio />} label="Chinese Vegetarian" />
                                <FormControlLabel value="Indian Vegetarian" control={<Radio />} label="Indian Vegetarian" />
                                <FormControlLabel value="Muslim (No Pork, No Lard)" control={<Radio />} label="Muslim (No Pork, No Lard)" />
                                <FormControlLabel value="Others" control={<Radio />} label="Others" />
                            </RadioGroup>
                            <Box sx={{ display: formValues.otherDietaryPreference.show ? 'block' : 'none', marginTop: '0.5rem', marginLeft: '1.75rem', maxWidth: '250px !important' }}>
                                <TextField id="otherDietaryPreference" name="otherDietaryPreference" className="form-input" type="text" placeholder="Please specify" aria-describedby="otherDietaryPreference-help" value={ formValues.otherDietaryPreference.value } error= { formValues.otherDietaryPreference.error } onChange={ e => handleChange(e.target.name, e.target.value) } onBlur={ e => handleChange(e.target.name, e.target.value) } disabled={ disableForm }></TextField>
                                <small id="otherDietaryPreference-help" className={ formValues.otherDietaryPreference.error ? "form-small-text d-flex" : "form-small-text d-none" }>
                                    <Icon className="form-icon" fontSize="small">error_outline</Icon>
                                    <span>This field is <strong style={{ display: 'inline-block' }}>required</strong>.</span>
                                </small>
                            </Box>
                            <small id="dietaryPreference-help" className={ formValues.dietaryPreference.error ? "form-small-text d-flex" : "form-small-text d-none" } style={{ marginTop: '0.5rem' }}>
                                <Icon className="form-icon" fontSize="small">error_outline</Icon>
                                <span>This field is <strong style={{ display: 'inline-block' }}>required</strong>.</span>
                            </small>
                        </FormControl>

                        <FormControl className="form-wrapper" error={ formValues.requireParking.error } disabled={ disableForm } style={ formValues.requireParking.show ? { display: 'block' } : { display: 'none' } }>
                            <FormLabel htmlFor="requireParking" className="form-label" sx={{ marginBottom: '0.5rem' }}>Would you like to request for complimentary parking? <span className="red-text">*</span></FormLabel>
                            <small className="help-text">As we are unable to guarantee a coupon for all our guests, in the event that we cannot extend this to you, we will let you know ahead of time.</small>
                            <RadioGroup
                                id="requireParking"
                                name="requireParking"
                                className="form-radio-group"
                                value={ formValues.requireParking.value }
                                onChange={ (event) => handleChange(event.target.name, event.target.value) }
                            >
                                <FormControlLabel value="Y" control={<Radio />} label="Yes, I would like to request for this." />
                                <FormControlLabel value="N" control={<Radio />} label="No, I would not require this." />
                            </RadioGroup>
                            <small id="requireParking-help" className={ formValues.requireParking.error ? "form-small-text d-flex" : "form-small-text d-none" } style={{ marginTop: '0.5rem' }}>
                                <Icon className="form-icon" fontSize="small">error_outline</Icon>
                                <span>This field is <strong style={{ display: 'inline-block' }}>required</strong>.</span>
                            </small>
                        </FormControl>

                        <FormControl className="form-wrapper">
                            <FormLabel htmlFor="contactNo" className="form-label" sx={{ marginBottom: '0.5rem' }}>Contact Number <span className="red-text">*</span></FormLabel>
                            <small className="help-text">We will use this number if we need to reach you urgently.</small>
                            <TextField id="contactNo" name="contactNo" className="form-input" type="text" placeholder="e.g. 91234567" InputProps={{ startAdornment: <InputAdornment position="start">+65</InputAdornment> }} aria-describedby="contactNo-help" value={ formValues.contactNo.value } error= { formValues.contactNo.errorRequired || formValues.contactNo.errorNumbers || formValues.contactNo.errorValid } onChange={ e => handleChange(e.target.name, e.target.value) } onBlur={ e => handleChange(e.target.name, e.target.value) } disabled={ disableForm }></TextField>
                            <small id="contactNo-help1" className={ formValues.contactNo.errorRequired ? "form-small-text d-flex" : "form-small-text d-none" }>
                                <Icon className="form-icon" fontSize="small">error_outline</Icon>
                                <span>It is important for us to have your <strong style={{ display: 'inline-block' }}>number</strong>.</span>
                            </small>
                            <small id="contactNo-help2" className={ formValues.contactNo.errorNumbers ? "form-small-text d-flex" : "form-small-text d-none" }>
                                <Icon className="form-icon" fontSize="small">error_outline</Icon>
                                <span>Only <strong style={{ display: 'inline-block' }}>numbers</strong> are allowed.</span>
                            </small>
                            <small id="contactNo-help3" className={ formValues.contactNo.errorValid ? "form-small-text d-flex" : "form-small-text d-none" }>
                                <Icon className="form-icon" fontSize="small">error_outline</Icon>
                                <span>Please enter a <strong style={{ display: 'inline-block' }}>valid</strong> contact number.</span>
                            </small>
                        </FormControl>

                        <FormControl className="form-wrapper" style={ formValues.email.show ? { display: 'block' } : { display: 'none' } }>
                            <FormLabel htmlFor="email" className="form-label" sx={{ marginBottom: '0.5rem' }}>Email Address (optional)</FormLabel>
                            <small className="help-text">Do let us have this should you wish to receive your table photos in high res, &/or simply keep in touch! :)</small>
                            <TextField id="email" name="email" className="form-input" type="text" placeholder="e.g. tanahkau@example.com" aria-describedby="email-help" value={ formValues.email.value } error= { formValues.email.error } onChange={ e => handleChange(e.target.name, e.target.value) } onBlur={ e => handleChange(e.target.name, e.target.value) } disabled={ disableForm }></TextField>
                            <small id="email-help" className={ formValues.email.error ? "form-small-text d-flex" : "form-small-text d-none" }>
                                <Icon className="form-icon" fontSize="small">error_outline</Icon>
                                <span>Please enter a <strong style={{ display: 'inline-block' }}>valid</strong> email address.</span>
                            </small>
                        </FormControl>

                        <FormControl className="form-wrapper">
                            <FormLabel htmlFor="messages" className="form-label" sx={{ marginBottom: '0.8rem' }}>Any message for us? (optional)</FormLabel>
                            <TextField id="messages" name="messages" className="form-input" type="text" placeholder="e.g. Happy marriage, 早生贵子, etc." aria-describedby="messages-help" multiline rows={ 5 } value={ formValues.messages.value } onChange={ e => handleChange(e.target.name, e.target.value) } onBlur={ e => handleChange(e.target.name, e.target.value) } disabled={ disableForm }></TextField>
                        </FormControl>

                        <Box className="submit-rsvp-button-container">
                            <Button type="submit" className="submit-rsvp-button" onClick={ () => submit() } disabled={ disableForm }>Submit</Button>
                        </Box>
                    </fieldset>
                </form>
            </Card>

            <ConfirmDialog mode={ "rsvp" } open={ confirmDialogOpen } onClose={ () => onDialogClose() } onSubmit={ () => onConfirmDialogSubmit() } />
            <SuccessDialog mode={ "rsvp" } open={ successDialogOpen } onClose={ () => onDialogClose() } onResubmit={ () => onSuccessDialogResubmit() } />
            <FailureDialog mode={ "rsvp" } open={ failureDialogOpen } onClose={ () => onDialogClose() } onRetry={ () => onFailureDialogRetry() } />
        </>
    );
}
export default RSVP;